import React from 'react'
import { BrowserRouter as Router, Routes,Route, Outlet} from "react-router-dom";
import { Navigate } from 'react-router-dom';
import UserLogIn from '../../Users/loginPage'
import InputLandingPage from '../../Users/mainLandingPage'

//camion
import TicketsPages from "../../Users/Tickets/ticketsPage";
import CommandesPage from '../../Users/Commandes/commandePage';
import CreditPage from '../../Users/Credit/creditPage'
import PositionPage from '../../Users/Info/positionPage'
import SuiviePage from '../../Users/Suivie/suiviePage'
import FidelietPage from '../../Users/Fidelite/fidelitePage';
 

const RedirectingPage = () => {
    const CamionIsLogged = localStorage.getItem('UserData');
    return (<>
        {
            CamionIsLogged ? <Navigate to='/User/L'  /> : <Navigate to='/User/logIn'  />
        } 
</>);}

const UserRouter = () => (
    <Route path="User" exact element={<Outlet />} >
            <Route path="" exact element={<RedirectingPage />} />
            <Route path="logIn" exact element={<UserLogIn />} />
            <Route path="L" exact element={<Outlet />} >
                    <Route path="" exact element={<InputLandingPage />} />
                    <Route path="tickets" exact element={<TicketsPages />} />
                    <Route path="tickets/info/:TID" exact element={<TicketsPages />} />
                    <Route path="commandes" exact element={<CommandesPage />} />
                    <Route path="credit" exact element={<CreditPage />} />
                    <Route path="fidelite" exact element={<FidelietPage />} />
                    <Route path="positions" exact element={<PositionPage />} />
                    <Route path="suivie" exact element={<SuiviePage />} />
            </Route>
    </Route>
)

export default UserRouter 