import axios from 'axios';
import React, { useEffect, useState } from 'react';
import TableGrid from '../Assets/tableGrid'
import BackCard from '../Assets/backCard';
import InputLinks from '../Assets/linksData';
import GConf from '../../AssetsM/generalConf';
import { _ } from 'gridjs-react';
import { Button, Icon } from 'semantic-ui-react';
import { useNavigate} from 'react-router-dom';

function CamionVente() {
    //*#########################[Const]##################################*/
    const navigate = useNavigate();
    let userData = JSON.parse(localStorage.getItem("UserData"));
    let [camionList, setCamioList] = useState([])
    let [articleList, setArticleList] = useState([])

    /*#########################[Use Effect]##################################*/
    useEffect(() => {
        axios.post(`${GConf.ApiLink}/User/reglemment`, {
            tag: userData.tag,
            clientId : '378884601',

          })
          .then(function (response) {
            let reglemmentTable = []
                response.data.map( (getData) => reglemmentTable.push([ getData.R_ID, new Date(getData.R_Date).toLocaleDateString('fr-FR').split( '/' ).reverse( ).join( '-' ), getData.Reglemment, getData.Reglemment.toFixed(3),
                _(<Button className='rounded-pill bg-system-btn' size='mini' onClick={ (e) => NavigateFunction(`/S/ft/info/${getData.R_ID}`)}><Icon  name='angle right' /></Button>)
                ],))
                setCamioList(reglemmentTable)
        
          })
        }, [])

    /*#########################[Functions]##################################*/
    const NavigateFunction = (link) => {  navigate(link) }
    /*#########################[Component]##################################*/ 
 

    return (  <>
        <BackCard data={InputLinks.backCard.cv}/>
        <br />

        <div className='container-fluid'>
            <TableGrid tableData={camionList} columns={['ID','Jour','Tems','Valeur']} /> 
        </div>
 
    </>);
}


export default CamionVente;