import React, { useEffect, useState} from 'react';
import { Bounce } from 'react-reveal';
import { Button } from 'semantic-ui-react';
import GConf from '../AssetsM/generalConf';
import LinkCard from './Assets/linksCard'
import InputLinks from './Assets/linksData'
import { NavLink } from 'react-router-dom';
import axios from 'axios';

function InputLandingPage() {
    /*#########################[Const]###########################*/
    let userData = JSON.parse(localStorage.getItem("UserData"));
    let [creditValue, setCreditValue] = useState(0)
    const inputIsLogged = JSON.parse(localStorage.getItem('UserData'));

    /*#########################[UseEffect]###########################*/
    useEffect(() => {
        const inputIsLogged = JSON.parse(localStorage.getItem('UserData'));
        if (!inputIsLogged) {window.location.href = "/User/logIn";}
         
        axios.post(`${GConf.ApiLink}/User/Landing`, {
            CL_ID : inputIsLogged.CL_ID,
            userGenre : inputIsLogged.Genre
        })
        .then(function (response) {
             
            setCreditValue(response.data[0].CRDT)
        })
    })

    /*#########################[Function]###########################*/
    const logOutInput = () =>{    
        localStorage.clear();
        window.location.href = "/User";
    }

    /*#########################[Card]###########################*/
    const MainTopCard = () =>{
        return(<>
            <div className='card p-3 fixed-top border-0 shadow-sm  rounded-0'>
                <div className='row'>
                    <div className='col-6 align-self-center'>
                        <NavLink exact='true' to='/'>
                            <span className='bi bi-arrow-left-short bi-md text-primary'></span>
                        </NavLink>

                    </div>
                    <div className='col-6 align-self-center text-end' ><h2> <span className="badge bg-info"> <span className='bi bi-person '></span>  {userData.CL_Name} </span></h2></div>
                    {/* <div className='col-2 align-self-center' ><Button onClick={logOutInput} style={{backgroundColor:GConf.themeColor}} className='rounded-circle text-white' icon='log out' /></div> */}
                </div>
            </div>
        </>)
    }

    return ( <>
            <MainTopCard />
            <br />                
            <br />                
            <br />                
            <br />   
                     
            <div className='container-fluid'>
                <div className='card card-body border-div mb-4'>
                    <h5 className='text-secondary text-start'>{inputIsLogged.Genre == 'Credit' ? 'Credit' : 'Fideliteé' } </h5> 
                    <h1 className='text-center display-1 mt-0'>{creditValue.toFixed(3)}</h1>
                </div>
                <div className='row'>
                    <div className='col-6 mb-3'> <LinkCard data={InputLinks.main[0]} /> </div>
                    <div className='col-6 mb-3'> <LinkCard data={InputLinks.main[1]} /> </div>
                </div>
                <div className='row'>
                    <div className='col-6 mb-3'> <LinkCard data={InputLinks.main[2]} /> </div>
                    <div className='col-6 mb-3'> <LinkCard data={InputLinks.main[3]} /> </div>
                </div>
                <div className='row'>
                    <div className='col-6 mb-3'> <LinkCard data={InputLinks.main[4]} /> </div>
                    <div className='col-6 mb-3'> <LinkCard data={InputLinks.main[5]} /> </div>
                </div>
                 
            </div>
              
        </> );
}

export default InputLandingPage;