import React, { useEffect } from 'react';
import { Button } from 'semantic-ui-react';
function PlayStorePage() {
    // useEffect(() => {
    //     window.location.href = 'https://play.google.com/store/apps/details?id=tn.ansl.familia.client.twa'
    // }, [])
    return ( <>
     <div className='container pt-5'> 
        <h1 className='text-end' dir='rtl'>رابط التحميل من هنا : </h1>
        <a href='https://play.google.com/store/apps/details?id=tn.ansl.familia.client.twa' className='btn btn-primary w-100'> Famila Market تطبيق   </a>
     </div> 
    </> );
}

export default PlayStorePage;