const InputLinks = {
    themeColor: '#2f8d99',
    main : [
        {id:1, link:'tickets', icon:'receipt-cutoff', text:'TICKETS', desc:''},
        {id:2, link:'credit', icon:'wallet', text:'CREDIT', desc:''},
        {id:5, link:'fidelite', icon:'gift', text:'FIDELITE', desc:''},
        {id:3, link:'positions', icon:'check2-square', text:'REGLEMMENT', desc:''},
        {id:6, link:'commandes', icon:'truck', text:'COMMANDES', desc:''},
        {id:4, link:'suivie', icon:'eye', text:'SUIVIE', desc:''},
        
        
    ],

    //return back card 
    backCard:{
        cv : {id:1, text:'Vente Des Camion', link:'/User/L'}, 
        cr : {id:1, text:'Resumer Des Camion', link:'/User/L'}, 
        sk : {id:1, text:'Stock', link:'/User/L'}, 
        ai : {id:1, text:'Article', link:'/User/L'}, 
        rp : {id:1, text:'Repture', link:'/User/L'}, 
        sg : {id:1, text:'Stat Génerale', link:'/User/L'}, 
        sr : {id:1, text:'Stat Régionale', link:'/User/L'}, 
    }
}
export default InputLinks