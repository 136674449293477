import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Dimmer, Form, Icon, Input, Loader, TextArea } from 'semantic-ui-react';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { MapContainer, Marker, Popup, TileLayer, useMapEvents  } from 'react-leaflet';
import axios from 'axios';
import GConf from '../../AssetsM/generalConf';
import Swal from 'sweetalert2'

const MapEventsHandler = ({ onLocationSelected }) => {
    useMapEvents({
      click: (e) => {
        const { lat, lng } = e.latlng;
        onLocationSelected({ lat, lng });
      },
    });
  
    return null;
};
const Location = ({position,handleLocationSelected}) =>{

    const [placeName, setPlaceName] = useState('');

    useEffect(() => {
        const loadPlaceName = async () => {
            const name = await fetchPlaceName(position.Lat, position.Lng);
            setPlaceName(name);
        };
        loadPlaceName();
    }, [position]);

    const fetchPlaceName = async (lat, lng) => {
        try {
            const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`);
            const data = await response.json();
            return data.display_name || 'Location not found';
        } catch (error) {
            console.error('Error fetching place name:', error);
            return 'Error fetching name';
        }
    };

    return(<>
         
        <div className='mt-1 mb-3'>
                
                <MapContainer center={[position.Lat,position.Lng]} zoom={16} scrollWheelZoom={false} className="map-height-lv  border-div">
                    {/* <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    /> */}
                    <TileLayer
    url="https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
    attribution='&copy; <a href="https://www.esri.com/en-us/home">ESRI</a>'
/>
                    <MapEventsHandler onLocationSelected={handleLocationSelected} />
                    <Marker position={[position.Lat,position.Lng]}>
                        <Popup>{placeName}</Popup>
                    </Marker>
                </MapContainer> 
                
        </div>
    </>)
}

function PannierPage() {
    let [pannierListe, setPannierListe] = useState([])
    const [longPress, setLongPress] = useState(false);
    let timeoutId;
    let livrerData = JSON.parse(localStorage.getItem('livrerData'))
    let UserData = JSON.parse(localStorage.getItem('UserData'))
    let [openD,setOpenD] = useState(false)
    let [loadingState,setLoadingState] = useState(false)
    const [clientD, setClientD] = useState({Name:'', Phone:'', Lan: 36.177334, Lng: 9.124188})
    
    L.Icon.Default.mergeOptions({
        iconUrl: require('leaflet/dist/images/position.gif'),
        iconRetinaUrl: require('leaflet/dist/images/position.gif'),
        iconSize: [10,10],
        shadowSize: [0,0],
        shadowUrl: '',
        shadowSize:   [0,0],
        iconAnchor:   [0,0],
        shadowAnchor: [0,0],
        popupAnchor:  [0,0]
    });


    useEffect(()=>{
        let storedPannier = localStorage.getItem('pannierList')
        if (storedPannier) { setPannierListe(JSON.parse(storedPannier)) }
        if (livrerData) { setClientD(livrerData) }
    },[])


    const OpenModalFun = () => {
         
        setOpenD(!openD)
    }
    const SaveCommandes = () => {
        
        if (!pannierListe || pannierListe.length == 0) { alert('Pannier Vide') } 
        else if (!livrerData) { alert('Entrer Info de Livraison') }
        else {
            setLoadingState(true)
            axios.post(`${GConf.ApiLink}/commande/ajouter`, {
                commandD : {client : livrerData, totale: AllPrice(), articles: pannierListe, Comments:'test'}
            })
            .then(function (response) {
                  
                Swal.fire({
                    title: "Termineé!",
                    text: "commande enregistrer avec success !",
                    icon: "success",
                }).then((result) => {
                    if (result.isConfirmed) {
                        localStorage.removeItem('pannierList');
                        window.location.href = '/';
                    }
                });
                 setLoadingState(false)
            })
        }
    }
    function haversineDistance(lat2, lon2) {
        const R = 6371; // Radius of the Earth in kilometers
        const toRadians = angle => (angle * Math.PI) / 180; // Convert degrees to radians
    
        const dLat = toRadians(lat2 - 36.177334);
        const dLon = toRadians(lon2 - 9.124188);
    
        const a = 
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRadians(36.177334)) * Math.cos(toRadians(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
            
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    
        return R * c; // Distance in kilometers
    } 
    const CalculateTotale = () => {
        let totale = 0 
        pannierListe.map((data) => {
            totale = totale + data.pTot
        })
        return parseFloat(totale).toFixed(3)
    }
    const CalculateLivration = () => {
        // OLD by distance 
        // let distance = (haversineDistance(clientD.Lan, clientD.Lng).toFixed(3)  * 1000 )
         
        // if ( distance < 100 ) { return 1.8 } 
        // else if (distance >= 100 && distance <= 200) { return 2.3}
        // else if (distance > 200 && distance <= 400) { return 2.8 }
        // else if (distance > 400 && distance <= 600) { return 3.3 }
        // else if (distance > 600 && distance <= 800) { return 3.8 }
        // else { return 4.3 }

        //new : by totale 
        let distance = CalculateTotale()
        if ( distance <= 0 ) { return 0 } 
        else if (distance > 0 && distance <= 5) { return 0.5}
        else if (distance > 5 && distance <= 10) { return 0.7 }
        else if (distance > 10 && distance <= 15) { return 1.3 }
        else if (distance > 15 && distance <= 20) { return 2.2 }
        else if (distance > 20 && distance <= 25) { return 2.8 }
        else if (distance > 25 && distance <= 30) { return 3 }
        else if (distance > 30 && distance <= 40) { return 3.5 }
        else if (distance > 40 && distance <= 50) { return 3.8 }
        else if (distance > 50 && distance <= 70) { return 4 }
        else { return 4.5 }

         
    }
    const AllPrice = () => {
        let pr = CalculateTotale()
        let lv = CalculateLivration()
        const sum = Number(pr) + Number(lv);
        return sum.toFixed(3)
    }
    const handleMouseDown = () => {
        timeoutId = setTimeout(() => {
          setLongPress(true);
        }, 500); // set the time duration for long press
    };
    const handleLocationSelected = (location) => {
        setClientD({ ... clientD , Lan: location.lat , Lng:location.lng })
    };
    const handleMouseUp = (targetValue) => {
        clearTimeout(timeoutId);
        if (longPress) {
          setLongPress(false);
          
          DeleteFunction(targetValue)
        }
    };
    const DeleteFunction = (targetValue) => {
        let indexWanted = pannierListe.findIndex((data) => data.A_Code == targetValue);
        
        // Create a new array with the element removed
        const updatedList = [
            ...pannierListe.slice(0, indexWanted),
            ...pannierListe.slice(indexWanted + 1)
        ];
        
        // Update the state and localStorage
        setPannierListe(updatedList);
        localStorage.setItem('pannierList', JSON.stringify(updatedList));
    };
    const UpdateLocationInfo = () => {
        localStorage.setItem('livrerData', JSON.stringify(clientD))
        setOpenD(!openD)
            
            Swal.fire({
                position: "top",
                text: " Info Enregistreé ! ",
                showConfirmButton: false,
                timer: 1500,
            });

    }
    const OnKeyPressFunc = (e) => {
        if (!((e.charCode >= 65 && e.charCode <= 90) || (e.charCode >= 97 && e.charCode <= 122) || (e.charCode >= 48 && e.charCode <= 57) || e.charCode == 42 || e.charCode == 32 || e.charCode == 47 )) {
            e.preventDefault();
        }   
    }



    const TopCard = () =>{
        return(<>
                <div className='card card-body shadow-sm bg-primary rounded-0 fixed-top'>
                    <NavLink exact='true' to='/'>
                       <span className='bi bi-arrow-left-short bi-md text-white'></span>
                    </NavLink>
                </div>
            </>)
    }
    const ArticleItemCard = (props) => {
        return(<>
            <div className='card p-2 shadow-sm border-div mb-2'  onMouseDown={handleMouseDown} onMouseUp={(e) => handleMouseUp(props.data.A_Code)}>
                <div className='row'   >
                    <div className='col-1 align-self-center '><img src={`https://assets.ansl.tn/Images/Articles/${props.data.Photo_Path}`} className='img-responsive rounded-circle' width='30px' height='30px' /></div>
                    <div className='col-7 align-self-center ps-3'> <b>{props.data.qte}</b> x {props.data.Name}</div>
                    <div className='col-2 align-self-center '>{props.data.pTot.toFixed(3)}</div>
                    <div className='col-2 align-self-center '><Button size='tiny' icon onClick={() => DeleteFunction(props.data.A_Code)}> <Icon name='trash' /></Button></div>
                </div>
            </div>
        </>)
    }
    const DisplayLivreData = () => {
        return (<>
        <div className='card-body mb-3 border-div' style={{border : '1px dashed grey'}}>
            <div className='row'>
                <div className='col-6 text-start align-self-center'>Info de livrasion </div>
                <div className='col-6 text-end align-self-center'><div className='text-primary' onClick={() => OpenModalFun()}>modifier</div></div>
            </div>
            <div className='text-start'><span className='bi bi-person'></span> {livrerData.Name}</div>  
            <div className='text-start'><span className='bi bi-phone'></span> {livrerData.Phone}</div>  
            <div className='text-start'><span className='bi bi-geo-alt'></span> {livrerData.Adress}</div>  
            
            
        </div>
        <br />
        <Button onClick={() => SaveCommandes()} >Terminer la commande  </Button>
        </>)
    }
    

    return ( <>
            <TopCard /> 
            <br />
            <br />
            <br />
            <br />
            <br />
            <Dimmer active={loadingState} inverted>
                <Loader inverted>Loading</Loader>
            </Dimmer>
            <div className='container mb-4'>
                {pannierListe.map((data,index) => <ArticleItemCard key={index} data={data} />)}
                <div className='card card-body shadow-sm border-div mb-3'>
                    <h3 className='text-start text-secondary mb-1'>Totale : {CalculateTotale()}</h3>
                    <h5 className='text-start  mb-1 mt-1'> + {CalculateLivration().toFixed(3)} : frait de livraison ({ haversineDistance(clientD.Lan, clientD.Lng).toFixed(3)  * 1000 } : métre) </h5>
                     <hr />
                     
                     <h5 className='mt-1 mb-4'>Nette a payeé = {AllPrice()}</h5>
                     {livrerData  ? <DisplayLivreData /> : <Button className='mb-3' onClick={() => OpenModalFun()}> Qui Et Où  ?  </Button>}
                </div>
                <BottomSheet   open={openD}  onDismiss={() => setOpenD(!openD)}  >
                    <div className='card-body'>
                        <div className='p-1  mb-2'>
                            <h5 className='mb-1'>Nom Et Prenon :</h5>
                            <Input icon='user' iconPosition='left' onKeyPress={event => OnKeyPressFunc(event)} placeholder='Nom Et Prenon ' className='w-100 border-0 shadow-sm rounded mb-1' value={clientD.Name} onChange={(e) => setClientD({...clientD, Name: e.target.value })} />
                        </div>
                        <div className='p-1 mb-2'>
                            <h5 className='mb-1'>Telephone :</h5>
                            <Input icon='phone' type='number' iconPosition='left' onKeyPress={event => OnKeyPressFunc(event)} placeholder='Numero' className='w-100 border-0 shadow-sm rounded mb-1' value={clientD.Phone} onChange={(e) => setClientD({...clientD, Phone: e.target.value })} />
                        </div>
                        <div className='p-1 mb-1'>
                             
                            <Form>
                                <TextArea  rows="2" onKeyPress={event => OnKeyPressFunc(event)} placeholder='adresse' className='w-100 shadow-sm rounded mb-3' value={clientD.Adress} onChange={(e) => setClientD({...clientD, Adress: e.target.value })}/>
                            </Form> 
                        </div>
                        <h5 className='mb-0 mt-0'>Position :</h5>
                        <Location position={{Lat: clientD.Lan, Lng: clientD.Lng}} handleLocationSelected={handleLocationSelected}  />
                        <Button className='mb-3' fluid onClick={() => UpdateLocationInfo()}> Enregistrer Info  </Button>
                    </div>

                </BottomSheet>
            </div>
        </> );
}

export default PannierPage;