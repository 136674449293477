import axios from 'axios';
import React, { useEffect, useState } from 'react';
import TableGrid from '../Assets/tableGrid'
import BackCard from '../Assets/backCard';
import InputLinks from '../Assets/linksData';
import GConf from '../../AssetsM/generalConf';
import { _ } from 'gridjs-react';
import { Button, Icon } from 'semantic-ui-react';
import { useNavigate} from 'react-router-dom';

function CamionVente() {
    //*#########################[Const]##################################*/
    const navigate = useNavigate();
    let userData = JSON.parse(localStorage.getItem("UserData"));
    let [camionList, setCamioList] = useState([])
    let [articleList, setArticleList] = useState([])

    /*#########################[Use Effect]##################################*/
    useEffect(() => {
        axios.post(`${GConf.ApiLink}/User/commandes`, {
            tag: userData.tag,
            clientId : '378884601',

          })
          .then(function (response) {
            let factureListContainer = []
            response.data.map( (getData) => factureListContainer.push([
            _(<img src={`https://assets.ansl.tn/Images/usful/facture.jpg`} alt="user-img" className='rounded-circle'  width="40px"  height="40px"  />),
            getData.T_ID,
            getData.CA_Name,
            new Date(getData.T_Date).toLocaleDateString('fr-FR').split( '/' ).reverse( ).join( '-' ),
            getData.T_Time,
            getData.Final_Value,
            _(<Button className='rounded-pill bg-system-btn' size='mini' onClick={ (e) => NavigateFunction(`/User/L/tickets/info/${getData.T_ID}`)}><span className='d-none d-lg-inline'> Info </span><Icon  name='angle right' /></Button>)
            ],))
            setCamioList(factureListContainer)
          })
        }, [])

    /*#########################[Functions]##################################*/
    const NavigateFunction = (link) => {  navigate(link) }
    /*#########################[Component]##################################*/ 
 

    return (  <>
        <BackCard data={InputLinks.backCard.cv}/>
        <br />

        <div className='container-fluid'>
            <TableGrid tableData={camionList} columns={['*','TID','Caisse','Date','Temps', 'totale','Voir']} /> 
        </div>
 
    </>);
}


export default CamionVente;