import axios from 'axios';
import React, { useEffect, useState } from 'react';
import TableGrid from '../Assets/tableGrid'
import BackCard from '../Assets/backCard';
import InputLinks from '../Assets/linksData';
import GConf from '../../AssetsM/generalConf';
import { _ } from 'gridjs-react';
import { Button, Icon } from 'semantic-ui-react';
 

function CamionVente() {
    //*#########################[Const]##################################*/
    let userData = JSON.parse(localStorage.getItem("UserData"));
    let [camionList, setCamioList] = useState([])
    let [articleList, setArticleList] = useState([])

    /*#########################[Use Effect]##################################*/
    useEffect(() => {
        axios.post(`${GConf.ApiLink}/User/suivie`, {
            tag: userData.tag,
            clientId : '378884601',

          })
          .then(function (response) {
             console.log(response.data)
            let testTable = []
            response.data.map( (getData) => testTable.push([
            getData.Name,
            getData.Qte,
            getData.PU.toFixed(3),
            ],))
            setCamioList(testTable)
          })
        }, [])

    /*#########################[Functions]##################################*/

    /*#########################[Component]##################################*/ 
 

    return (  <>
        <BackCard data={InputLinks.backCard.cv}/>
        <br />

        <div className='container-fluid'>
            <TableGrid tableData={camionList} columns={['Mois','Totale','Points']} /> 
        </div>
 
    </>);
}


export default CamionVente;