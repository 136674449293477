import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Menu } from 'semantic-ui-react';
import AvatarGroup from '@atlaskit/avatar-group';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

function LandingPage() {
    /* ############### Const #################*/
    const alimentaire = [
        {id:1, link:'CHAMIA', genre:'CHAMIA', text:'SABRA', img:'https://assets.ansl.tn/Images/Articles/1667288610534-4437678942701.jpeg', },
        {id:2, link:'THON', genre:'THONS', text:'SMART CHEF', img:'https://assets.ansl.tn/Images/Articles/1665477031496-4528804508556.jpeg',},
        {id:3, link:'SARDINE', genre:'SARDINE', text:'ARTISTO', img:'https://assets.ansl.tn/Images/Articles/1667467332675-233970006089.png' ,},
        {id:4, link:'PREPARATION', genre:'PREPARATION', text:'SAIDA', img:'https://assets.ansl.tn/Images/Articles/1667295244652-2607216838826.jpeg',},
        {id:5, link:'CHOCOLAT', genre:'CHOCOLAT', text:'PRESIDENT', img:'https://assets.ansl.tn/Images/Articles/1666087980579-277614519006.jpeg',},
        {id:6, link:'CAFE', genre:'CAFE', text:'SMAG', img:'https://assets.ansl.tn/Images/Articles/1666184467876-119274463899.jpeg',},
        {id:7, link:'CONFITURE', genre:'CONFITURE', img:'https://assets.ansl.tn/Images/Articles/1662374626601-2593587312454.jpeg', },
        {id:8, link:'TOMATE', genre:'TOMATE', text:'IFFCO', img:'https://assets.ansl.tn/Images/Articles/1662375121616-6772938585193.jpeg',},
        {id:9, link:'HARISSA', genre:'HARISSA', text:'IFFCO', img:'https://assets.ansl.tn/Images/Articles/1667459186230-3180348714090.jpeg',},
        {id:10, link:'CONSERVE', genre:'CONSERVE', text:'IFFCO', img:'https://assets.ansl.tn/Images/Articles/1667034562528-6274836176669.jpeg',},
        {id:11, link:'HUILE', genre:'HUILE', text:'IFFCO', img:'https://assets.ansl.tn/Images/Articles/1691998385618-1632719035707.jpeg',},
        {id:12, link:'CHIPS', genre:'CHIPS', text:'IFFCO', img:'https://assets.ansl.tn/Images/electro/bugles.jpg',},
        {id:13, link:'JUS', genre:'JUS ', text:'IFFCO', img:'https://assets.ansl.tn/Images/Articles/1683280540322-1672271535600.jpeg',},
        {id:14, link:'CAKE', genre:'CAKE', text:'IFFCO', img:'https://assets.ansl.tn/Images/Articles/1665389837894-6646056568614.jpeg',},
        {id:15, link:'BISCUIT', genre:'BISCUIT', text:'IFFCO', img:'https://assets.ansl.tn/Images/Articles/6194008516046.jpg',},
        {id:16, link:'FROMAGE', genre:'FROMAGE', text:'IFFCO', img:'https://assets.ansl.tn/Images/Articles/1691998313502-9773487659558.jpeg',},
        {id:17, link:'VINAIGRES', genre:'VINAIGRES', text:'IFFCO', img:'https://assets.ansl.tn/Images/electro/vinagre.JPG',},
        {id:18, link:'FARINE', genre:'FARINE', text:'IFFCO', img:'https://assets.ansl.tn/Images/Articles/1665839922462-9191634903180.jpeg',},
        {id:19, link:'COUSCOUS', genre:'COUSCOUS ', text:'IFFCO', img:'https://assets.ansl.tn/Images/Articles/1665827653576-1664514772397.jpeg',},
        {id:20, link:'PATTE', genre:'PATTE', text:'IFFCO', img:'https://assets.ansl.tn/Images/Articles/1020.jpg',},
        {id:21, link:'SEMOULE', genre:'SEMOULE', text:'IFFCO', img:'https://assets.ansl.tn/Images/electro/semoule.png',},
        {id:22, link:'EPICE', genre:'EPICE', text:'IFFCO', img:'https://assets.ansl.tn/Images/Articles/1666357930313-3114910321798.jpeg',},
        {id:23, link:'BOISSON', genre:'BOISSON', text:'IFFCO', img:'https://assets.ansl.tn/Images/Articles/1683282050024-3217067981510.jpeg',},
        {id:24, link:'DELICE', genre:'DELICE', text:'IFFCO', img:'https://assets.ansl.tn/Images/electro/delice.png',},
        {id:25, link:'VITALAIT', genre:'VITALAIT ', text:'IFFCO', img:'https://assets.ansl.tn/Images/electro/vitalait.png',},
        {id:26, link:'NATILAIT', genre:'NATILAIT', text:'IFFCO', img:'https://assets.ansl.tn/Images/electro/natilait.jpg',},
        {id:27, link:'YAB', genre:'YAB', text:'IFFCO', img:'https://assets.ansl.tn/Images/electro/yab.jpg',},
        {id:28, link:'CONTOIRE', genre:'CONTOIRE', text:'IFFCO', img:'https://assets.ansl.tn/Images/Articles/1687513539046-9677392944227.jpeg',}
    ]
    const cosmetique = [
        {id:1, link:'CHAMPOING', genre:'SHAMPOING', text:'PEAUDOUCE', img:'https://assets.ansl.tn/Images/Articles/1667030660192-953562916134.jpeg',state:''},
        {id:2, link:'COUCHE 1', genre:'COUCHE', text:'JUDY', img:'https://assets.ansl.tn/Images/Articles/1663575305170-9437367382794.png',},
        {id:3, link:'LINGETTES', genre:'LINGETTES', text:'HENKEL', img:'https://assets.ansl.tn/Images/Articles/1664009647034-2997132136324.jpeg' ,},
        {id:4, link:'OMO', genre:'OMO', text:'BSP', img:'https://assets.ansl.tn/Images/Articles/1665390531977-3161863812072.jpeg',},
        {id:5, link:'DETERGENT', genre:'DETERGENT', text:'EJM', img:'https://assets.ansl.tn/Images/Articles/1665823326659-7949407789690.png' ,},
        {id:6, link:'SOLSURFACE', genre:'SOLSURFACE', text:'UNULEVRE', img:'https://assets.ansl.tn/Images/Articles/1667299071418-8654902881929.jpeg',},
        {id:7, link:'JAVEL', genre:'JAVEL', text:'Lingettes Softy', img:'https://assets.ansl.tn/Images/Articles/1663844325859-1836765187037.png',},
        {id:8, link:'VAISSELLE', genre:'VAISSELLE', text:'NIHEL', img:'https://assets.ansl.tn/Images/Articles/1665484520690-6085886081119.png' ,},
        {id:9, link:'ASSOUPLISSANT', genre:'ASSOUPLISSANT', text:'NIHEL', img:'https://assets.ansl.tn/Images/Articles/1667030117789-7591227147328.jpeg',},
        {id:10, link:'AIRFRESH', genre:'AIRFRESH', text:'NIHEL', img:'https://assets.ansl.tn/Images/Articles/1665479698037-6734206028828.jpeg',},
        {id:11, link:'PLASTIQUE', genre:'PLASTIQUE', text:'NIHEL', img:'https://assets.ansl.tn/Images/Articles/1667036707361-499905484483.jpeg',},
        {id:12, link:'ESTHETIQUE', genre:'MAKE UP', text:'NIHEL', img:'https://assets.ansl.tn/Images/electro/makeUp.jpg',},
        {id:13, link:'DIVERS', genre:'MENAGE', text:'NIHEL', img:'https://assets.ansl.tn/Images/Articles/1667496335959-7282321251365.png',},
    ]
    const electro = [
        {id:1, link:'REF', genre:'REFREGIRATEUR', text:'PEAUDOUCE', img:'https://assets.ansl.tn/Images/electro/ref.jpg',state:''},
        {id:2, link:'MAL', genre:'MAL', text:'JUDY', img:'https://assets.ansl.tn/Images/electro/mal.png',},
        {id:3, link:'TV', genre:'TELEVISIONS', text:'HENKEL', img:'https://assets.ansl.tn/Images/electro/tv.png' ,},
        {id:4, link:'CUIS', genre:'CUIS & PLAQUES', text:'BSP', img:'https://assets.ansl.tn/Images/electro/four.jpg',},
        {id:5, link:'HAUTES', genre:'CHAUFF.B', text:'EJM', img:'https://assets.ansl.tn/Images/electro/mal.png' ,},  
    ]

    /* ############### UseEffect #################*/

    /* ############### Functions #################*/
    
    /* ############### Card #################*/
    const TopCard = () =>{
        return(<>
                <div className='card card-body shadow-sm text-end bg-primary rounded-0 fixed-top'>
                    <NavLink to='User'>
                        <Button size='mini'  className='rounded-pill'>  <span className='bi bi-person-circle me-1'></span> CONNEXTION </Button>
                    </NavLink>
                </div>
                <div className='mb-4' style={{marginTop:'58px'}}>
                        <Carousel showThumbs={false}  autoPlay  >
                            <div className='max-height-image'>
                                <img src="https://assets.ansl.tn/Images/2.jpg" className='d-block'  width="100%" height="auto" />
                            </div>
                            
                            <div className='max-height-image'>
                                <img src="https://assets.ansl.tn/Images/1.jpg" className='d-block'  width="100%" height="auto" />
                            </div>
                           
                            <div className='max-height-image'>
                                <img src="https://assets.ansl.tn/Images/3.jpg" className='d-block'  width="100%" height="auto" />
                            </div>
                            <div className='max-height-image'>
                                <img src="https://assets.ansl.tn/Images/4.jpg" className='d-block'  width="100%" height="auto" />
                            </div>
                            <div className='max-height-image'>
                                <img src="https://assets.ansl.tn/Images/5.jpg" className='d-block'  width="100%" height="auto"/>
                            </div>
                            <div className='max-height-image'>
                                <img src="https://assets.ansl.tn/Images/electro.jpg" className='d-block'  width="100%" height="auto" />
                            </div>
                        </Carousel>

                    {/* <img src='https://assets.ansl.tn/Images/electro.jpg' className='img-responsive' width='100%' height='280px' /> */}
                </div>
            </>)
    }

    const BottomCard = () =>{
        return(<>
                <div className='card card-body shadow-sm mb-4 bg-dark ' style={{borderRadius:'30px 30px 0 0'}}>
                    <div className='row'>
                        <div className="col-lg-6 col-12 text-left align-self-center text-white">
                            <div className="p-3">
                                <h6 className="this-font">
                                     FAMILIA MARKET 
                                </h6>
                                <br />
                                <h6><span className="bi bi-telephone-fill"></span> 97913914</h6>
                                <h6><span className="bi bi-geo-alt-fill"></span>   ste familia market , rue de la liberté , sidi bourouis , siliana 6113</h6>
                                <a href="https://www.facebook.com/profile.php?id=100064880016607" className="text-white"><span className="bi bi-facebook "></span> Familia Market - A </a>
                            </div>   
                        </div>
                        {/* <div className="col-lg-6 col-12 align-self-center">
                            <div className="p-3">
                                <iframe width="100%" height="200" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" className="rounded" 
                                    src="https://www.openstreetmap.org/export/embed.html?bbox=9.097194671630861%2C36.16195863769561%2C9.145088195800783%2C36.18170539608309&amp;layer=mapnik&amp;marker=36.171832638869326%2C9.12114143371582" 
                                    >
                                </iframe>
                                <a className="btn btn-block btn-danger btn-sm" target="c_blank" href="https://www.google.com/maps/@36.177331,9.124139,602m/data=!3m1!1e3"> <span className="fa fa-map"></span> Voir Sur Google Map <span className="fa fa-angle-double-right float-right"></span></a>
                            </div>
                        </div> */}
                    </div>
                </div>
            </>)
    }
    const GenreCard = (props) =>{
        const ItemCard = (props) =>{
            return(<>
                    <div className={`col-6 col-lg-${props.data.col} mb-3`}>
                        <NavLink to={`/List/${props.tag}/${props.data.link}`}>
                        <div className='card card-body shadow-sm mb-4 text-center border-div  h-100'>
                            <div className='d-flex justify-content-center mt-3'>
                                <img src={props.data.img} className='img-responsive rounded-circle'  width='90px' height='90px' />
                            </div>
                            <h5> {props.data.genre} </h5>
                        </div>
                        </NavLink>
                    </div>
            </>)
        }
        return(<>
                <div className='row'>
                    {props.list.map( (itemData,index) => <ItemCard key={index} data={itemData} tag={props.tag}/>)}
                </div>
        </>)
    }
    const ItemCard = (props) => {
           
        return(
            <>     
                <Menu.Item className='ms-1 me-1 p-0'>
                    <NavLink exact='true' to={`/List/${props.electro ? 'electro' : 'alimentaire'}/${props.data.link}`} >
                        <div className='card p-2 m-0 border-div '>
                            <div className='row  '>
                                <div className='col-4 align-self-center'><img className='img-responsive mb-0' src={props.data.img}  width='50px' height='50px' /></div>
                                <div className='col align-self-center text-start'><h5 className="text-start font-droid text-secondary mt-0"> {props.data.genre} </h5></div>
                            </div>
                        </div>
                    </NavLink>
                </Menu.Item>
            </>
        )
    }

    return ( <>
            <TopCard />
            <br /> 
            
            <div className='container'>
                <h3>RAYON 1</h3>
                <div className="mt-1 p-1 mb-2 d-inline-flex"   style={{width:'100%', overflowX: 'auto', overflowY : 'hidden', whiteSpace:'nowrap'}} >
                    <Menu secondary >
                        <ItemCard data={alimentaire[0]}  /> 
                        <ItemCard data={alimentaire[1]}  /> 
                        <ItemCard data={alimentaire[2]}  /> 
                        <ItemCard data={alimentaire[3]}  /> 
                        <ItemCard data={alimentaire[6]}  /> 
                        <ItemCard data={alimentaire[7]}  /> 
                        <ItemCard data={alimentaire[8]}  /> 
                        <ItemCard data={alimentaire[9]}  />
                        <ItemCard data={alimentaire[10]}  />
                    </Menu>
                </div>

                <h3>RAYON 2</h3>
                <div className="mt-1 p-1 mb-2 d-inline-flex"   style={{width:'100%', overflowX: 'auto', overflowY : 'hidden', whiteSpace:'nowrap'}} >
                    <Menu secondary >
                        <ItemCard data={alimentaire[5]}  /> 
                        <ItemCard data={alimentaire[12]}  /> 
                        <ItemCard data={alimentaire[13]}  /> 
                        <ItemCard data={alimentaire[14]}  /> 
                        <ItemCard data={alimentaire[15]}  /> 
                        <ItemCard data={alimentaire[11]}  /> 
                        <ItemCard data={alimentaire[16]}  /> 
                    </Menu>
                </div>

                <h3>RAYON 3</h3>
                <div className="mt-1 p-1 mb-2 d-inline-flex"   style={{width:'100%', overflowX: 'auto', overflowY : 'hidden', whiteSpace:'nowrap'}} >
                    <Menu secondary >
                        <ItemCard data={alimentaire[17]}  /> 
                        <ItemCard data={alimentaire[18]}  /> 
                        <ItemCard data={alimentaire[19]}  /> 
                        <ItemCard data={alimentaire[20]}  /> 
                        <ItemCard data={alimentaire[22]}  /> 
                        <ItemCard data={cosmetique[10]}  />
                    </Menu>
                </div>

                <h3>FRIGO & COMPTOIRE </h3>
                <div className="mt-1 p-1 mb-2 d-inline-flex"   style={{width:'100%', overflowX: 'auto', overflowY : 'hidden', whiteSpace:'nowrap'}} >
                    <Menu secondary >
                        <ItemCard data={alimentaire[23]}  /> 
                        <ItemCard data={alimentaire[24]}  /> 
                        <ItemCard data={alimentaire[25]}  /> 
                        <ItemCard data={alimentaire[26]}  />  
                        <ItemCard data={alimentaire[27]}  />  
                    </Menu>
                </div>

                <h3>RAYON 5</h3>
                <div className="mt-1 p-1 mb-2 d-inline-flex"   style={{width:'100%', overflowX: 'auto', overflowY : 'hidden', whiteSpace:'nowrap'}} >
                    <Menu secondary >
                        <ItemCard data={cosmetique[0]}  /> 
                        <ItemCard data={cosmetique[1]}  /> 
                        <ItemCard data={cosmetique[2]}  /> 
                        <ItemCard data={cosmetique[3]}  /> 
                    </Menu>
                </div>

                <h3>RAYON 6</h3>
                <div className="mt-1 p-1 mb-2 d-inline-flex"   style={{width:'100%', overflowX: 'auto', overflowY : 'hidden', whiteSpace:'nowrap'}} >
                    <Menu secondary >
                        <ItemCard data={cosmetique[4]}  /> 
                        <ItemCard data={cosmetique[5]}  /> 
                        <ItemCard data={cosmetique[6]}  /> 
                        <ItemCard data={cosmetique[7]}  /> 
                        <ItemCard data={cosmetique[12]}  /> 
                        <ItemCard data={cosmetique[8]}  /> 
                        <ItemCard data={cosmetique[9]}  /> 
                        <ItemCard data={cosmetique[11]}  /> 
                        
                    </Menu>
                </div>

                <h3>ELECTROMENAGER</h3>
                <div className="mt-1 p-1 mb-2 d-inline-flex"   style={{width:'100%', overflowX: 'auto', overflowY : 'hidden', whiteSpace:'nowrap'}} >
                    <Menu secondary >
                        <ItemCard electro data={electro[0]}  /> 
                        <ItemCard electro data={electro[1]}  /> 
                        <ItemCard electro data={electro[2]}  /> 
                        <ItemCard electro data={electro[3]}  /> 
                        <ItemCard electro data={electro[4]}  /> 
                    </Menu>
                </div>     
            </div>
            <br />
            <BottomCard />
        </> );
}

export default LandingPage;