import React, { useEffect, useRef, useState } from 'react';
import { _ } from "gridjs-react";
import axios from 'axios';
import { NavLink, useParams } from 'react-router-dom';
import GConf from '../../AssetsM/generalConf';
import { toast } from 'react-toastify';
import SKLT from '../../AssetsM/usedSlk';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { Button, Input } from 'semantic-ui-react';
import Swal from 'sweetalert2';

const FilterCard = ({searchTerm,FilterFunction}) =>{
    return(<>
    <div className='card p-2 shadow-sm rounded mb-3  sticky-top' style={{top:'70px', zIndex:1}}>
        <h5 className='text-secondary mb-1'>Recherche ...</h5>
        <Input fluid className='mb-2' value={searchTerm}  placeholder='recherche ...' onChange={(e) => FilterFunction(e.target.value)} />
    </div>
    </>)
}


function ArticleList() {
    /*#########################[Const]##################################*/
    let {genre, tag} = useParams()
    let [articleList, setArticleList] = useState([]); 
    let [reservedList, setReservedList] = useState([]); 
    let [loading, setLOD] = useState(true); 
    let [openD,setOpenD] = useState(false)
    let [selectedArticle, setSelectedArticle] = useState({})
    let [qteValue, setQteValue] = useState(1)
    const [searchTerm, setSearchTerm] = useState('');

    /* ############### UseEffect #################*/
    useEffect(() => {
        
            axios.post(`${tag == 'electro' ? 'https://api.system.ansl.tn/API/Electro/' : GConf.ApiLink}/list`, {
                genre:genre
            })
            .then(function (response) {
                 let rest = response.data.filter((data) => !(data.Photo_Path === 'default_img.jpg' && data.Quantite == 0))
                setArticleList(rest)
                setReservedList(rest)
                setLOD(false)
            })
        
      }, [])
    /* ############### Functions #################*/
      const OpenModalFun = (valueTraged) => {
        let TragetArticle = articleList.find((data) => data.A_Code == valueTraged)
        setSelectedArticle(TragetArticle)
        setQteValue(1)
        setOpenD(!openD)
      }
      const AddToPannierFunction = () => {
        let pannierList = localStorage.getItem('pannierList')
        if (pannierList) {
            let Liste = JSON.parse(pannierList)
            Liste.push({A_Code: selectedArticle.A_Code, Name: selectedArticle.Name, Photo_Path : selectedArticle.Photo_Path, PU: selectedArticle.Prix_vente, pTot: (selectedArticle.Prix_vente * qteValue),  qte: qteValue})
            localStorage.setItem('pannierList', JSON.stringify(Liste))
            setOpenD(!openD)
            
            Swal.fire({
                position: "top",
                text: "Article ajouteé aux pannier  ",
                showConfirmButton: false,
                timer: 1500,
            });
        }else {
            localStorage.setItem('pannierList', JSON.stringify([{A_Code: selectedArticle.A_Code, Name: selectedArticle.Name, Photo_Path : selectedArticle.Photo_Path, PU: selectedArticle.Prix_vente, pTot: (selectedArticle.Prix_vente * qteValue),  qte: qteValue}]))
            setOpenD(!openD)
            Swal.fire({
                position: "top",
                text: "Article ajouteé aux pannier  ",
                showConfirmButton: false,
                timer: 1500,
            });
        }

      }
      const FilterFunction = (text) => {
        setSearchTerm(text);
        let rest = reservedList.filter((data) => 
            data.Name.toLowerCase().includes(text.toLowerCase())
        );
        // Update the displayed data (e.g., set a state with filtered data)
        setArticleList(rest);

        // let rest = reservedList.filter((data) => data.Name.includes(text))
        // setArticleList(rest)  
    }
    /* ############### Card #################*/
    
    const TopCard = () =>{
        return(<>
                <div className='card card-body shadow-sm bg-primary rounded-0 fixed-top'>
                    <div className='row'>
                        <div className='col-6 text-start'>
                            <NavLink exact='true' to='/'>
                            <span className='bi bi-arrow-left-short bi-md text-white'></span>
                            </NavLink>
                        </div>
                        <div className='col-6 text-end'>
                            <NavLink to='/pannier'>
                                <Button size='mini'  className='rounded-pill mb-0'>  <span className='bi bi-basket me-1'></span> PANNIER </Button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </>)
    }
    const ArticleList = (props) =>{
        const ItemCard = (props) =>{
            return(<>
                    <div className='col-6 col-lg-3 mb-3'>
                        {/* <NavLink to={`/Article/${tag}/${props.data.A_Code}`}> */}
                        <div className='card p-3  text-center h-100 border-div' onClick={() => OpenModalFun(props.data.A_Code)}>
                            <div>
                                <img src={`https://assets.ansl.tn/Images/Articles/${props.data.Photo_Path}`} className='img-responsive' width='50%' heigth='60px' />
                            </div>
                            <h6 className='mt-2 mb-0 text-dark text-truncate' style={{maxWidth: '95%'}}> {props.data.Name} </h6>
                            <div className='row'>
                                <div className='col-6 text-start align-self-center'><h5 className='mt-1 mb-0 text-start text-dark'> {tag == 'electro' ? props.data.Prix_vente : props.data.Prix_vente.toFixed(3)} </h5></div> 
                                <div className='col-6 text-end align-self-center'>
                                    {
                                        props.data.Quantite != 0 ? <div className='text-success'><span></span> En Stock </div>  : <div className='text-danger'><span></span> Repture </div> 
                                    }
                                </div> 
                            </div>
                            
                        </div>
                        {/* </NavLink> */}
                    </div>
            </>)
        }
        return(<>
                <div className='row'>
                    {props.list.map( (itemData,index) => <ItemCard key={index} data={itemData}/>)}
                </div>
        </>)
    }
    
    return ( <>
            <TopCard /> 
            <br />
            <br />
            <br />
            <br />
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-lg-3  '>
                        <FilterCard searchTerm={searchTerm} FilterFunction={FilterFunction} />
                    </div>
                    <div className='col-12 col-lg-9'>
                        <h3>Selectionneé : {genre} </h3> 
                        { loading ? SKLT.CardList : <ArticleList list={articleList} />}
                    </div>
                </div>
                
            </div>
            <br />
            <BottomSheet expandOnContentDrag open={openD}  onDismiss={() => setOpenD(!openD)}  >
                {selectedArticle.A_Code ? 
                    <div className='card-body'>
                    <div className='text-center'>
                        <img src={`https://assets.ansl.tn/Images/Articles/${selectedArticle.Photo_Path}`} className='img-responsive' width='50%' heigth='60px' />
                    </div>
                    <h3 className='mt-2 mb-0 text-dark text-truncate' style={{maxWidth: '95%'}}> {selectedArticle.Name} </h3>
                    <div className='row mb-4'>
                        <div className='col-6 text-start align-self-center'>
                            <h5 className='mt-1 mb-0 text-start text-dark'> {selectedArticle.Prix_vente.toFixed(3)} </h5>
                        </div> 
                        <div className='col-6 text-end align-self-center'>
                            {
                                selectedArticle.Quantite != 0 ? <div className='text-success'><span></span> En Stock </div>  : <div className='text-danger'><span></span> Repture </div> 
                            }
                        </div> 
                    </div>
                    <hr />
                    <div className='row'>
                        <div className='col-2 align-self-center'>
                            
                            <h5>{qteValue}</h5>
                        </div>
                        <div className='col-6'>
                            <Button className='bg-danger text-white' onClick={() => setQteValue(qteValue -1 )}><span className='bi bi-dash'></span></Button>
                            <Button className='bg-success text-white' onClick={() => setQteValue(qteValue + 1 )}><span className='bi bi-plus'></span></Button>
                        </div>
                        <div className='col-4'>
                                <Button className='' fluid onClick={() => AddToPannierFunction()}>  <small>Ajouter</small> </Button>
                        </div>
                    </div>
                </div>
                :
                <>
                </>}
                    
            </BottomSheet>
        </> );
}

export default ArticleList;